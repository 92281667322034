import type { H3Event } from 'h3'

const LOCALES = ['en', 'es', 'fr']

export const LANGUAGE_HEADER = 'X-Accept-Language'

/**
 * Get the locale from the query string
 */
export function getLocale(event: H3Event) {
   const locale = getHeader(event, LANGUAGE_HEADER)

   setResponseHeader(event, 'Netlify-Vary', `header=${LANGUAGE_HEADER}`)
   setResponseHeader(event, 'Vary', LANGUAGE_HEADER)

   if (typeof locale === 'string' && LOCALES.includes(locale)) {
      return locale
   }

   return 'en'
}
